import React, { useEffect, useRef, useState } from "react"
import Flickity from "react-flickity-component"
import "flickity/dist/flickity.min.css"
import { Link, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import MarkDown from "react-markdown"

//component
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import LiveChat from "../../components/Home/livechat";
import TestimonialsText from "../../components/Home/testimonials-text"

// img
import Wacomelight1 from "../../../images/uploads/wacomstore/wacomelight1.png"
import Wacomedark1 from "../../../images/uploads/wacomstore/wacomedark1.png"
import Wacom2 from "../../../images/uploads/wacomstore/wacom2.png"
import Wacom3 from "../../../images/uploads/wacomstore/wacom3.png"
import Wacom4 from "../../../images/uploads/wacomstore/wacom4.png"
import Wacom5 from "../../../images/uploads/wacomstore/wacom5.png"
import wacom6light from "../../../images/uploads/wacomstore/wacom6light.png"
import wacom6dark from "../../../images/uploads/wacomstore/wacom6dark.png"
import Wacom7 from "../../../images/uploads/wacomstore/wacom7.png"
import Wacoms1 from "../../../images/uploads/wacomstore/wacoms1.png"
import Wacoms2 from "../../../images/uploads/wacomstore/wacoms2.png"
import Wacoms3 from "../../../images/uploads/wacomstore/wacoms3.png"
import Wacoms4 from "../../../images/uploads/wacomstore/wacoms4.png"
import Wacoms5 from "../../../images/uploads/wacomstore/wacoms5.png"
import Wacoms6 from "../../../images/uploads/wacomstore/wacoms6.png"
import Wacoms7 from "../../../images/uploads/wacomstore/wacoms7.png"
import Wacoms8 from "../../../images/uploads/wacomstore/wacoms8.png"
import Wacoms9 from "../../../images/uploads/wacomstore/wacoms9.png"
import Wacoms10 from "../../../images/uploads/wacomstore/wacoms10.png"
import Wacoms11 from "../../../images/uploads/wacomstore/wacoms11.png"
import Wacoms12 from "../../../images/uploads/wacomstore/wacoms12.png"
import Wacoms13 from "../../../images/uploads/wacomstore/wacoms13.png"
import Wacoms14 from "../../../images/uploads/wacomstore/wacoms14.png"
import Wacoms15 from "../../../images/uploads/wacomstore/wacoms15.png"

import addcs1 from "../../../images/uploads/decodeup-Frattire-additional-case-study.png"
import addcs2 from "../../../images/uploads/decodeup-rexperts-additional-case-study.png"

const WacomStore = props => {

  const SEOdata = props.data.pageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.pageData.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.pageData.edges[0].node.frontmatter?.Hreflang

  const [margin, setMargin] = useState()
  const flRef = useRef()

  useEffect(() => {
    let box = document.querySelector(".container")
    let width = box.offsetWidth
    let innerw = window.innerWidth
    let paddingApplied = innerw - width

    setMargin(paddingApplied)
  })

  const options = {
    // contain: true,
    // wrapAround: true,
    // initialIndex: 2,
    // pageDots: false,
    // autoPlay: false,
    // prevNextButtons: false,
    // adaptiveHeight: true,
    lazyLoad: true,
    prevNextButtons: true,
    cellAlign: "left",
    contain: true,
    freeScroll: true,
    pageDots: false,
    percentPosition: true,
    adaptiveHeight: true,
  }

  const onMouseEnter = () => {
    flRef.current.flkty.player.play()
  }
  const onMouseLeave = () => {
    flRef.current.flkty.player.stop()
  }
  return (
    <>
      <Layout>
        <Seo
          title={SEOdata ? SEOdata.seo_title : "wacomstore"}
          description={SEOdata ? SEOdata.seo_description : ""}
          keywords={SEOdata ? SEOdata.seo_keyword : ""}
          image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
          single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
          currentPageUrlPath={props.location.pathname}
          image_format={SEOdata ? SEOdata.image_format : ""}
          image_height={SEOdata ? SEOdata.feature_image_height : ""}
          img_width={SEOdata ? SEOdata.img_width : ""}
          facebook_url={SEOdata ? SEOdata.facebook_url : ""}
          twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
          twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
          twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
          twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
          page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
          page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
          sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
          breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
          logo_schema={Schemadata ? Schemadata.logo_schema : ""}
          organization_schema={Schemadata ? Schemadata.organization_schema : ""}
          aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
          local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
          site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
          speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
          hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
          hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
          hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
          hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
        />


        {/* banner  section */}
        <section className="h-full xl:h-screen case-draft-section">
          <div className="h-full container mx-auto  ">
            <div className="pb-20 pt-1-63 md:py-2-00 lg:pt-64 h-full flex flex-col justify-center">
              <div className="mb-6-6 lg:mb-50 w-full  case-study-project-heading ">
                <h4 className="pr-5 lg:pl-0 mb-1-2 lg:mb-3-2 font-poppings-normal text-p3 lg:text-p2 text-term-primary">
                  Responsive eCommerce Store
                </h4>
                <h1 className="mb-3 lg:mb-5 font-poppings-bold text-h2 lg:text-h0 xl:text-h1 text-term-primary">
                  Online Store for Tablets and Stylus Products
                </h1>
                <p className="w-full lg:w-11/20  font-worksans-normal text-p4  md:text-h5 lg:text-p2 text-term-primary">
                  A Wacom has online stores at wacom.com, wacomstore.com.br,
                  wacom.animazu.hk/en, and various partner sites. The
                  organization requires an online store exclusively for Canadian
                  consumers. With this need, the entrepreneur Kellen came to us
                  to create the online store to compete with and potentially
                  exceed all other Wacom online shopping experiences available
                  to Canadian customers.
                </p>
              </div>

              <div className="project-details-draft">
                <div className="flex flex-wrap lg:flex-nowrap">
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      CLIENT
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Kellen M
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      COUNTRY
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Canada
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      SERVICES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Development, Maintenance and Support, UI/UX Design
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      TECHNOLOGIES USED
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Woo-Commerce, WordPress
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      INDUSTRIES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Business to Business
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Banner img section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <img
            className="w-full object-cover wacomelight"
            src={Wacomelight1}
            alt="WacomeStore Decodeup Technologies"
          />
          <img
            className="w-full object-cover wacomedark"
            src={Wacomedark1}
            alt="WacomeStore Decodeup Technologies"
          />
        </section>

        {/* intro section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-waco mx-auto">
            <div className="flex flex-wrap md:flex-nowrap gap-8 md:gap-8 lg:gap-16 items-center">
              <div className="order-2 md:order-1 w-full md:w-1/2">
                <img
                  className="mx-auto w-full h-full object-contain object-top"
                  src={Wacom2}
                  alt="WacomeStore Decodeup Technologies"
                />
              </div>
              <div className="order-1 md:order-2 w-full md:w-1/2">
                <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  A standard e-commerce website including all the features for
                  selling
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  You’ve heard it before, but it's worth repeating: you need a
                  website regardless of your size or industry.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The e-commerce store represents all the Wacom products such as
                  Pen Computers, Pen Displays, Drawing Tablets and Accessories
                  to Canadian Customers.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* intro bold section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-waco mx-auto">
            <div className="flex flex-wrap sm:flex-nowrap items-center gap-8 lg:gap-16 xl:gap-32">
              <div className="w-full md:w-5/65">
                <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  With the need to draw in Canadian customers and compete on a
                  large scale we got to work crafting a shopping website that
                  would help Wacom enter the homes of shoppers everywhere. But
                  first we ran into a few challenges, although it was nothing
                  our team could not handle.
                </h3>
              </div>
              <div className="w-full md:w-5/35">
                <img
                  className="mx-auto w-full h-full object-cover"
                  src={Wacom3}
                  alt="WacomeStore Decodeup Technologies"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Challenge section */}
        <section className="py-60 lg:pt-1-20 lg:pb-60 wc-yellow">
          <div className="container container-waco mx-auto">
            <div className="mb-3 lg:mb-6-6 ">
              <h2 className="pr-10 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-dark-blue">
                The Challenges
              </h2>
            </div>
            <div className="flex flex-wrap sm:flex-nowrap gap-8 lg:gap-32">
              <div className="w-full md:w-1/2">
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-dark-blue font-worksans-normal">
                  We’re proud of the working relationships we have with our all
                  of clients. Need us for an hour, a year, or more? No problem,
                  our commitment is to make your product the best it can
                  possibly be.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-dark-blue font-worksans-normal">
                  Such was the case with Kellen's project.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-dark-blue font-worksans-normal">
                  The DecodeUp team so impressed that one week quickly turned
                  into many. New features and functionality were in development
                  and implemented based on our collaborative working sessions.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-dark-blue font-worksans-normal">
                  They had the strategy and requirements planned, but they
                  needed a partner to understand their intention and goals,
                  recommend the best technology, and bring the platform to life.
                </p>
              </div>
              <div className="w-full md:w-1/2">
                <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-dark-blue">
                  They wanted a gold-level website that feels like home.
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-dark-blue font-worksans-normal">
                  A premium customer experience (CX) and user experience (UX)
                  representing Wacom. The online store will contend with and
                  potentially exceed other Wacom digital shopping experiences
                  available to its Canada-based customers.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-dark-blue font-worksans-normal">
                  Create unique designs from existing Wacom stores.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-dark-blue font-worksans-normal">
                  Represent all products on this new website.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-dark-blue font-worksans-normal">
                  Revenue recognition from Day One.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-dark-blue font-worksans-normal">
                  High-ranking SEO performance
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* Challenge img section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <img
            className="w-full object-cover"
            src={Wacom4}
            alt="WacomeStore Decodeup Technologies"
          />
        </section>

        {/* solution section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-waco mx-auto">
            <div className="w-full mb-8 lg:mb-16">
              <h2 className="font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary text-center">
                Solutions
              </h2>
            </div>
            <div className="mb-8 md:mb-10">
              <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                Creating a fully-customized design
              </h3>
              <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                Their priorities were user experience, customer experience,
                engagement, affordability and revenue generation from day 1,
                similar to other available stores. By selecting resources like
                layout design, assets, domain setup, and personally hosting for
                the site, they reduced development costs (we never charge for
                work you don’t need!).
              </p>
              <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                On the website, the new homepage represents the information
                about the Wacom Product, its main features, Purpose, and
                benefits. The best selling products section has drawn customers'
                attention for the reasons the website was created.
              </p>
            </div>
            <div className="flex flex-wrap sm:flex-nowrap gap-8 lg:gap-32">
              <div className="w-full md:w-1/2">
                <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Student Discounts
                </h3>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The best feature Wacom provides to Canadian students who are
                  enrolled in or accepted for enrollment in secondary or
                  post-secondary accredited institutions is direct contact with
                  admin and discount on Wacom products.
                </p>
              </div>
              <div className="w-full md:w-1/2">
                <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  A completed checklist
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Enhanced UI/UX? Check. Accurate reflection of the brand?
                  Check. Focus on the core purpose of the website? Check.
                </p>

                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The whole e-commerce experience has been built with the site
                  from product display to payment flow with standard payment
                  gateways integrations and the client is very satisfied after
                  seeing the work done by the DecodeUp team.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* solution img */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-waco mx-auto">
            <img
              className="w-full object-cover"
              src={Wacom5}
              alt="WacomeStore Decodeup Technologies"
            />
          </div>
        </section>

        {/* Type Face */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="mb-10 w-full ">
              <h2 className="font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                Typeface & Colors
              </h2>
            </div>
            <div className="mb-8 lg:mb-12 flex flex-wrap md:flex-nowrap gap-4 md:gap-8 lg:gap-16 items-center justify-between">
              <div className="w-full md:w-1/4 max-w-480">
                <img className="wacomelight" src={wacom6light} />
                <img className="wacomedark" src={wacom6dark} />
              </div>
              <div className="w-full mms:w-5/20 md:w-2/4 wacom-typo text-term-primary">
                <h4 className="mb-3 lg:mb-8 text-term-primary">Robot</h4>
                <h5 className="mb-3 lg:mb-3 text-term-primary opacity-70">
                  ABCDEFGHIJKLMNOPQRSTUVWXYZ
                </h5>
                <h6 className="text-term-primary opacity-70">
                  abcdefghijklmnopqrstuvwxyz
                </h6>
              </div>
              <div className="mt-8 mms:mt-0 w-full mms:w-5/48 md:w-2/4 flex mms:justify-end items-center text-term-primary">
                <div className="flex justify-center items-center rounded-full w-2-72 h-2-72 wacom-color-1">
                  <p className="text-rp1 lg:text-h3 font-poppings-bold text-white text-center">
                    #00A3E0
                  </p>
                </div>
                <div className="mms:-ml-4 lg:-ml-8 xl:-ml-16 relative z-1 flex justify-center items-center rounded-full w-2-72 h-2-72 wacom-color-2">
                  <p className="text-rp1 lg:text-h3 font-poppings-bold text-white text-center">
                    #111111
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Result */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cor mx-auto">
            <div className="flex flex-wrap md:flex-nowrap gap-8 lg:gap-16 xl:items-center">
              <div className="md:w-7/10">
                <h2 className="mb-4 lg:mb-6-6 pr-10 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                  The Result
                </h2>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  We delivered a visually appealing, streamlined, intuitive site
                  that is aesthetically pleasing and easy to update regularly.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The result? Kellen loved the new design and if the client is
                  happy, we’re happy. A good website is never truly done. Like
                  your house, it’s necessary to upgrade, reorganize, and
                  renovate occasionally.
                </p>
                <div className="mt-6 w-full">
                  <h3 className="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                    A 100% satisfactory product has been delivered to the Wacom
                    team and will help them grow their business rapidly. In
                    return, we got a long-term partnership for various project
                    phases as well.
                  </h3>
                </div>
              </div>
              <div className="w-full md:w-5/20 md:max-w-4-64">
                <img
                  className="mx-auto w-full h-full object-contain object-top"
                  src={Wacom7}
                  alt="Cordialsa Decodeup Technologies"
                />
              </div>
            </div>
          </div>
        </section>
        {/* slider section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="case-study-slider-bg">
            <div className="container container-waco mx-auto">
              <div
                id="myid"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                <Flickity
                  className={"case-studies-carousel cstm-du-casestudy-slider"}
                  elementType={"div"}
                  options={options}
                  ref={flRef}
                >
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Wacoms1}
                    alt="WacomeStore Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Wacoms2}
                    alt="WacomeStore Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Wacoms3}
                    alt="WacomeStore Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Wacoms4}
                    alt="WacomeStore Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Wacoms5}
                    alt="WacomeStore Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Wacoms6}
                    alt="WacomeStore Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Wacoms7}
                    alt="WacomeStore Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Wacoms8}
                    alt="WacomeStore Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Wacoms9}
                    alt="WacomeStore Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Wacoms10}
                    alt="WacomeStore Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Wacoms11}
                    alt="WacomeStore Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Wacoms12}
                    alt="WacomeStore Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Wacoms13}
                    alt="WacomeStore Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Wacoms14}
                    alt="WacomeStore Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Wacoms15}
                    alt="WacomeStore Decodeup Technologies"
                  />
                  {/* {info.screenshots_for_carousel.map(
                        ({ image: img }, ss) => (
                          <img
                            key={ss}
                            src={getSrc(img)}
                            className="cs-carousel-image"
                            alt="decodeup case study"
                          />
                        )
                      )} */}
                </Flickity>
              </div>
            </div>
          </div>
        </section>

        {/* client testimonials carousel section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-waco mx-auto">
            <div className="pr-2 pl-5-3 lg:pl-60 lg:pr-60 xl:px-90 xl:pl-1-41 xl:pr-1-41 2xl:pl-1-86 2xl:pr-1-87">
              <TestimonialsText />
            </div>
          </div>
        </section>

        {/* additional case studies section */}
        <section className="pb-50 md:pt-50 lg:pt-62 xl:pt-0 lg:pb-1-20">
          <div className="container mx-auto">
            <div className="pb-35 lg:pb-36 text-center">
              <h3 className="d-head-title text-rp1  lg:text-h3 font-poppings-bold text-term-primary">
                Additional Case Studies
              </h3>
            </div>
            <div className="h-full flex flex-wrap">
              <div className="px-4-1 pt-36 w-full sm:w-1/2 text-center bg-cyan">
                <Link
                  to="/case-study/custom-clothing-design-ecommerce"
                  className="h-full flex items-center justify-between flex-col text-center addi-case-links"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Custom Clothing Design & E-commerce
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className=""
                      src={addcs1}
                      alt="Frattire Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div>
              <div className=" pt-36 w-full sm:w-1/2 text-center bg-background-case">
                <Link
                  // to={post.fields.slug}
                  to="/case-study/real-estate-buy-sell-rent-platform"
                  className="h-full flex items-center justify-between flex-col text-center"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Real Estate Buy, Sell & Rent Platform
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className=" w-full h-full object-cover"
                      src={addcs2}
                      alt="Realty Experts Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <LiveChat />
      </Layout>
    </>
  )
}

export default WacomStore

export const pageQuery = graphql`
query wacomstorepagequery {
  pageData: allMarkdownRemark(
    filter: { fileAbsolutePath: { regex: "/case-studies/online-store-for-tablets-stylus.md/" } }
  ) {
    edges {
      node {
        frontmatter {
          seo_metadata {
            seo_title
            seo_keyword
            seo_description
            image_format
            feature_image_height
            img_width
            facebook_url
            twitter_page_username
            twitter_image_alt_tag
            twitter_estimated_reading_lable
            twitter_estimated_reading_time
            page_publish_date
            page_last_modified_time
            seo_Image {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 1200)
              }
            }
            seo_Single_Image
          }
          Schema {
            sitelinks_schema
            breadcrumb_schema
            logo_schema
            organization_schema
            aggregate_rating_schema
            local_business_schema
            site_navigation_schema
            speakable_specification_schema
          }
          Hreflang {
            hreflang_1
            hreflang_2
            hreflang_3
            hreflang_4
          }
        }
      }
    }
  }
}

`;